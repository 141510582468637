<template>
  <b-card class="mb-0">
    <b-row>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>Maling Address : </strong>{{ item.shortViews.maling_address }}
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>Scac Code : </strong>{{ item.shortViews.scac_code }}
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>DBA Name : </strong>{{ item.shortViews.dba_name }}
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>US Dot Number : </strong>{{ item.shortViews.usdot }}
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>MC/MX/FF : </strong>{{ item.shortViews.operating_authority }}
      </b-col>
      <b-col
        md="4"
        class="mb-1"
      >
        <strong>Insurance Expiring Date : </strong>{{ item.shortViews.insurance_expire_date }}
      </b-col>
    </b-row>
    <hr class="w-100">
    <h5 class="mb-1 bold">
      Representive:
    </h5>
    <b-row
      v-for="(representive, index) in item.representives"
      :key="index"
    >
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>Full Name : </strong>{{ representive.full_name }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>Email Address : </strong>{{ representive.email_address }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>Contact Phone : </strong>{{ representive.contact_phone }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>Cell Phone : </strong>{{ representive.cell_phone }}
      </b-col>
    </b-row>
    <hr class="w-100">
    <h5 class="mb-1">
      Vendor Files
    </h5>
    <b-row>
      <b-table
        striped
        hover
        responsive
        :small="true"
        :items="item.files"
        :fields="fields"
      >
        <template #cell(type)="row">
          <b-img
            :src="iconType(row.item.type)"
            class="iconType"
          />
        </template>
        <template #cell(name)="row">
          <b-link
            :href="row.item.path"
            target="_blank"
            class="text-secondary"
          >
            {{ row.item.name }}
            <feather-icon
              icon="ExternalLinkIcon"
              size="12"
            />
          </b-link>
        </template>
        <template #cell(action)="">
          <feather-icon
            icon="Trash2Icon"
            size="12"
            class="text-danger"
          />
        </template>
      </b-table>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BLink, BImg, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BTable,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      DocumentIcon: require('@/assets/images/upload/document.png'),
      ImageIcon: require('@/assets/images/upload/image.png'),
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'name', label: 'Name' },
        { key: 'action', label: '#' },
      ],
    }
  },
  methods: {
    iconType(type) {
      let iconType = ''
      if (type === 'jpg' || type === 'jpeg' || type === 'png') {
        iconType = this.ImageIcon
      } else if (type === 'pdf' || type === 'doc' || type === 'docx' || type === 'csv' || type === 'txt' || type === 'xlx' || type === 'xls' || type === 'xlsx') {
        iconType = this.DocumentIcon
      } else {
        iconType = ''
      }
      return iconType
    },
  },
}
</script>

<style>
   .iconType {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
</style>
