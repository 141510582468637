/* eslint-disable no-shadow */
import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getVendors = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.vendor.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const create = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.vendor.create}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const edit = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.vendor.edit}/${id}`, { headers })
    .then(res => {
      if (res.data.success) {
        resolve(res.data)
      }
    })
    .catch(err => {
      reject(err)
    })
})

export const update = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.vendor.update}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const deleteVendor = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.vendor.delete}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getVendorShortDetails = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.vendor.getVendorShortDetails}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
